<template>
    <EmptyState
        class="empty-search"
        buttonIcon="clipboard"
        icon="clipboard"
        title="There are no results with that search."
        description="You can try again with different filters."
    />
</template>

<script>
import EmptyState from '@/components/shared/layout/EmptyState.vue';

export default {
    name: 'EmptySearch',
    components: {
        EmptyState,
    },
};
</script>

<style lang="stylus">
    .empty-search {
        card-base();
        width: 100%;
        padding: $nebula-space-3x 0 $nebula-space-2x;
    }
</style>
