<template>
    <NebulaAccordion ref="accordion" :text="title" :icon="icon" class="browse__filter-accordion">
        <fieldset class="nebula-fieldset">
            <legend class="nebula-legend nebula-screenreader-only">Select areas of expertise</legend>
            <slot />
            <slot name="selector">
                <div
                    class="nebula-checkbox-group"
                    v-for="(item, idx) in items"
                    :key="`${topic}-${idx}`"
                >
                    <NebulaCheckbox
                        size="s"
                        :isDisabled="isDisabled"
                        :labelText="item.text || item.label"
                    >
                        <template v-slot:checkbox>
                            <input
                                class="nebula-checkbox"
                                :id="`${topic}-${item.value}`"
                                :name="item.value"
                                :checked="checked(item)"
                                :value="item.value"
                                type="checkbox"
                                @input="handleCheck(item.value)"
                            >
                        </template>
                    </NebulaCheckbox>
                </div>
            </slot>
        </fieldset>
    </NebulaAccordion>
</template>

<script>
import { NebulaCheckbox, NebulaAccordion } from '@discoveryedu/nebula-components';

export default {
    name: 'FilterList',
    components: {
        NebulaAccordion,
        NebulaCheckbox,
    },
    props: {
        items: {
            type: Array,
        },
        topic: {
            // use for creating ids
            type: String,
            required: true,
        },
        title: {
            type: String,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: 'subject',
        },
        selected: Array,
    },
    methods: {
        handleCheck(value) {
            const data = {
                value,
                topic: this.topic,
            };
            this.$emit('checkbox-toggled', data);
        },
        collapse() {
            this.$refs.accordion.collapse();
        },
        checked(item) {
            if (!this.selected) {
                return false;
            }

            const { value } = item;
            if (Array.isArray(value)) {
                const notIncluded = value.find((each) => !this.selected.includes(each));
                if (notIncluded) {
                    return false;
                }
                return true;
            }
            return this.selected.includes(item.value);
        },
    },
};
</script>

<style lang="stylus">
.browse__filter-accordion {
    width: 265px;
    .nebula-checkbox__container {
        width: 100%;
    }
    .nebula-checkbox__text {
        line-height: 1em;
    }
    .nebula-checkbox__visual {
        flex-shrink: 0;
    }
}
</style>
