import { pastStatuses, futureStatuses } from '@/constants';
import {
    inXdays,
    timestampNow,
} from '@/data/placeholder/date';

import pagination from './pagination';

const browse = {
    methods: {
        getFilterParams() {
            const toConvert = [];
            const supported = [
                'startDate',
                'endDate',
                'status',
                'subject',
                'type',
                'grade_level',
                'employee_status',
                'tag',
                'industry',
                'timeslot',
                'language',
                'organization',
                'dateTimeRange',
            ];
            const filterTypes = Object.keys(this.filters).filter((each) => supported.includes(each));

            filterTypes.forEach((type) => {
                if (this.filters[type].length > 0) {
                    this.filters[type].forEach((filter) => {
                        toConvert.push([type, filter]);
                    });
                }
            });

            return toConvert;
        },
        getSortParams() {
            const { sort, sortConfig } = this;
            const config = sortConfig[sort];
            const params = [];

            if (config.sort) {
                params.push(['sort', config.sort]);
            }

            if (config.order) {
                params.push(['order', config.order]);
            }

            return params;
        },
        getCombinedParams() {
            // initialize with pagination params (limit and offset)
            let toConvert = this.getPaginationParams ? this.getPaginationParams() : [];

            const filterParams = this.getFilterParams();
            const sortParams = this.getSortParams();
            toConvert = toConvert.concat(filterParams).concat(sortParams);

            if (this.resultsCallback) {
                toConvert = this.resultsCallback(toConvert);
            }

            return new URLSearchParams(toConvert);
        },
        async handleSort(option) {
            this.sort = option.value;

            await this.getResults();
        },
        filterUpdater(data) {
            // if the filter has already been applied, this is an uncheck. Remove
            if (this.filters[data.topic].includes(data.value)) {
                const match = this.filters[data.topic].findIndex((each) => each === data.value);
                this.filters[data.topic].splice(match, 1);
            } else {
                // otherwise, add it in
                this.filters[data.topic].push(data.value);
            }
        },
        async updateFilter(data) {
            const { value, topic } = data;

            if (Array.isArray(value)) {
                value.forEach((each) => this.filterUpdater({ topic, value: each }));
            } else {
                this.filterUpdater(data);
            }
        },
        async handleFilter(data, fetch = true) {
            const { topic } = data;
            const { startDate, endDate } = this.filters;
            await this.updatePagination(1);
            this.updateFilter(data);

            if (topic === 'status' && startDate && endDate) {
                this.tabActive[2] = null;
                const filtersIncludePast = !!(this.filters.status.find((each) => pastStatuses.includes(each)));
                const filtersIncludeFuture = !!(this.filters.status.find((each) => futureStatuses.includes(each)));

                if (!filtersIncludePast && !filtersIncludeFuture) {
                    // would only come up for drafts and cancellations
                    this.filters.startDate = [inXdays(-365)];
                    this.filters.endDate = [inXdays(365)];
                }

                if (filtersIncludePast) {
                    this.filters.startDate = [inXdays(-365)];
                } else {
                    this.filters.startDate = [timestampNow()];
                }

                if (filtersIncludeFuture) {
                    this.filters.endDate = [inXdays(365)];
                } else {
                    this.filters.endDate = [timestampNow()];
                }
            }

            if (fetch) {
                await this.getResults();
            }
        },
    },
};

export { pagination, browse };
