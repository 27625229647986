<template>
    <NebulaPopover
        id="filter-popover"
        placement="bottom"
        ref="filterPopover"
        showCloseButton
    >
        <template v-slot:content>
            <FilterTitle @clear-all-clicked="clearAll" />
            <slot />
        </template>
        <template v-slot:trigger="{ togglePopover, popoverOpen }">
            <NebulaButton
                class="filter-popover__button"
                :aria-expanded="popoverOpen && 'true' || 'false'"
                aria-haspopup="true"
                aria-label="open filters"
                icon-left="options"
                size="s"
                type="flat"
                id="nebula-popover-trigger__filter-popover"
                @click="togglePopover"
                text="Filter"
            />
        </template>
    </NebulaPopover>
</template>

<script>
import { NebulaButton, NebulaPopover } from '@discoveryedu/nebula-components';
import FilterTitle from '@/components/shared/tokens/FilterTitle.vue';

export default {
    name: 'FilterPopover',
    components: {
        FilterTitle,
        NebulaButton,
        NebulaPopover,
    },
    methods: {
        clearAll() {
            this.$emit('clear-all-clicked');
        },
    },
    mounted() {
        this.$watch(() => this.$refs.filterPopover.popoverOpen, (updated) => {
            if (!updated) {
                this.$emit('close');
            }
        });
    },
};
</script>

<style lang="stylus">
.filter-popover {
    &__button {
        --nebula-button-background-hover: var(--nebula-color-platform-interface-200);
        --nebula-button-background-active: var(--nebula-color-platform-interface-300);
        --nebula-button-text-color: var(--nebula-color-platform-interface-900);
        --nebula-button-text-color-hover: var(--nebula-color-platform-interface-1000);
        --nebula-button-text-color-active: var(--nebula-color-platform-interface-1000);
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            margin-inline-end: $nebula-space-2x;
        }
    }
}
</style>
