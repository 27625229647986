<template>
    <NebulaVueSelect
        class="sort-dropdown"
        :clearable="false"
        :is-searchable="false"
        :options="options"
        placeholder="Sort by"
        size="s"
        @change-selected="handleFunction"
    />
</template>

<script>
import { NebulaVueSelect } from '@discoveryedu/nebula-components';

export default {
    name: 'SortDropdown',
    components: {
        NebulaVueSelect,
    },
    props: {
        options: Array,
        handleFunction: Function,
    },
};
</script>

<style lang="stylus">
.sort-dropdown {
    --nebula-vs-dropdown-toggle-border: 1px solid $nebula-color-platform-interface-400;
    margin: 0;
    width: 100%;
    @media (min-width: $nebula-breakpoints-tablet-portrait) {
        width: 235px;
    }
}
</style>
