<template>
    <NebulaGridRow type="flex" class="pagination-row">
        <NebulaColumn :columns="3" :mdSize="8" class="pagination-row__select-block">
            <p>Items per page</p>
            <NebulaVueSelect
                v-if="!loading"
                class="pagination-row__page-size-select"
                :options="pageSizes"
                v-model="pagination.perPage"
                :clearable="false"
                @change-selected="changePageSize"
            />
        </NebulaColumn>
        <NebulaColumn :columns="9" :mdSize="8" class="pagination-row__page-block">
            <p>Results: {{ resultsString }}</p>
            <NebulaPagination
                ref="pagination"
                v-if="paging && showTotal"
                @selectPage="handlePageChange"
                :link-generator="paging"
            />
        </NebulaColumn>
    </NebulaGridRow>
</template>

<script>
import {
    NebulaGrid,
    NebulaPagination,
    NebulaVueSelect,
} from '@discoveryedu/nebula-components';
import { browse } from '@/constants';
import pagination from '@/utils/pagination';

export default {
    name: 'PaginationRow',
    components: {
        NebulaColumn: NebulaGrid.NebulaColumn,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaPagination,
        NebulaVueSelect,
    },
    props: {
        loading: Boolean,
        paging: Object,
        pagination: Object,
        resultsLength: Number,
        updatePagination: Function,
        showTotal: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        pageCount() {
            return this.pagination.pageCount;
        },
        resultsString() {
            const { totalRecords, page, perPage } = this.pagination;
            const offset = page > 1 ? (page - 1) * perPage : 0;
            const startRange = offset + 1;

            const ofTotal = this.showTotal ? ` of ${totalRecords}` : '';
            const endRange = offset + this.pageCount;
            return this.loading ? 'loading' : `${startRange}–${endRange}${ofTotal}`;
        },
    },
    data() {
        return {
            offset: 0,
            pageSizes: browse.pageSizes,
        };
    },
    methods: {
        changePageSize(selected) {
            const { value: newPageSize } = selected;

            if (Number(newPageSize) === this.pagination.perPage) {
                return;
            }

            const pageURL = new URL(window.location.href);
            pageURL.searchParams.set('per_page', newPageSize);
            window.location.href = pageURL.toString();
        },
        async handlePageChange(page) {
            const baseParams = pagination.getBaseParams();
            if (Object.keys(baseParams).length > 0) {
                await this.$store.dispatch('updatePage', { params: baseParams });
            }
            await this.updatePagination(page);
            this.$emit('select-page');
        },
    },
};
</script>

<style lang="stylus">
.pagination-row {
    margin-block-start: $nebula-space-5x;

    &__select-block {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $nebula-space-2x;
        order: 2;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            justify-content: flex-start;
            order: 0;
        }

        p {
            margin: 0;
        }
    }

    &__page-block {
        display: flex;
        align-items: center;
        gap: $nebula-space-2x;
        justify-content: center;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            justify-content: flex-end;
        }
        p {
            margin: 0;
        }
    }

    &__page-size-select {
        margin: 0;
        width: 95px;
    }
}
</style>
