const setUrlParam = (key, value) => {
    const params = new URLSearchParams(window.location.search);
    params.set(key, value);
    const { origin, pathname } = window.location;
    const newUrl = `${origin}${pathname}?${params.toString()}`;
    window.history.pushState(
        {},
        null,
        newUrl,
    );
};

const getUrlParam = (key) => {
    const params = new URLSearchParams(window.location.search);
    params.get(key);
    return params.get(key);
};

const removeUrlParam = (key, value) => {
    const params = new URLSearchParams(window.location.search);
    params.delete(key, value);

    const { origin, pathname } = window.location;
    const newUrl = `${origin}${pathname}?${params.toString()}`;
    window.history.pushState(
        {},
        null,
        newUrl,
    );
};

export {
    setUrlParam,
    removeUrlParam,
    getUrlParam,
};
