const getBaseParams = () => {
    // make sure other url params are persisted when using pagination
    // const paginationLinks = document.querySelectorAll('.nebula-pagination__link');

    const currentLocation = new URL(window.location.href);
    const baseParams = {};
    const paginationParams = ['per_page', 'page'];

    const supported = ['1', '2'];
    currentLocation.searchParams.keys()
        .filter((each) => !paginationParams.includes(each) && supported.includes(each))
        .forEach((each) => {
            baseParams[each] = currentLocation.searchParams.get(each);
        });

    return baseParams;
};

export default { getBaseParams };
