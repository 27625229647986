import {
    NebulaPaginationLinkGenerators,
} from '@discoveryedu/nebula-components';
import { setUrlParam, getUrlParam } from '@/utils/urlParams';
import { browse } from '@/constants';
// import pagination from '@/utils/pagination';

export default {
    data() {
        return {
            paging: null,
            pagination: {
                baseUrl: null,
                page: 1,
                perPage: 12,
                totalRecords: 0,
                usePushState: true,
                useRouterLink: true,
            },
        };
    },
    computed: {
        paginationFromAPI() {
            return this.$store.state.page.pagination;
        },
        perPage() {
            return this.pagination.perPage;
        },
    },
    methods: {
        initializePagination() {
            this.pagination.baseUrl = window.location.pathname;
            this.paging = new NebulaPaginationLinkGenerators.PageGenerator(this.pagination);
        },
        updateOffset() {
            const { page, perPage } = this.pagination;
            this.offset = page > 1 ? (page - 1) * perPage : 0;
        },
        async updatePagination(page) {
            if (this.pagination.page === page) {
                return;
            }

            this.pagination.page = page;
            this.updateOffset();
        },
        applyPageSettings() {
            const { page } = this.$route.query;
            let { per_page: perPage } = this.$route.query;

            // if a page size comes in via the path, apply that
            const pageSizeOptions = browse.pageSizes.map((each) => each.value);
            if (perPage && pageSizeOptions.includes(getUrlParam('per_page'))) {
                this.pagination.perPage = Number(perPage);
            } else {
                // otherwise fall back to the default
                perPage = this.pagination.perPage;
            }

            this.offset = 0;

            if (page) {
                this.pagination.page = Number(page);
            }

            this.updateOffset();
        },
        getPaginationParams() {
            return [
                ['limit', this.pagination.perPage],
                ['offset', this.offset],
            ];
        },
    },
    watch: {
        paginationFromAPI(updated) {
            if (!updated) {
                return;
            }
            const {
                recordCount: totalRecords,
                limit: perPage = 12,
                pageCount,
                page,
            } = updated;

            // bail if no pagination data, this might mean some other change to the page object in the store
            if (!pageCount) {
                return;
            }

            if (getUrlParam('page') !== page) {
                setUrlParam('page', page);
            }
            if (getUrlParam('per_page') !== perPage) {
                setUrlParam('per_page', perPage);
            }

            this.pagination = {
                ...this.pagination,
                ...{
                    totalRecords,
                    perPage,
                    pageCount,
                    page,
                },
            };

            this.initializePagination();

            const { params } = this.$store.getters;

            if (params) {
                Object.keys(params).forEach((key) => {
                    setUrlParam(key, params[key]);
                });
            }
        },
    },
};
